"use client";
import { useState } from "react";
import dynamic from "next/dynamic";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import style from "@/app/styles/Molecules/SingleVideo.module.scss";
import Image from "next/image";
import PlayArrowSharpIcon from "@mui/icons-material/PlayArrowSharp";
import sanitizeText from "@/app/helpers/sanitizers/sanitizeText";
import handleSelectContent from "@/app/helpers/analytics/handleSelectcontent";
import { usePathname } from "next/navigation";
import handleSelectContentECommerce from "@/app/helpers/analytics/handleSelectContentECommerce";
const PlayerVodV2 = dynamic(
	() => import("@/app/components/molecules/PlayerVodV2/PlayerVodV2")
);

type Props = {
	cardItem?: any;
	alterTitle?: String;
	noLowerDescription?: boolean;
	manualPause?: boolean;
	dataLayer?: any;
	isVertical?: boolean;
	isLiveBlog?: boolean;
	isAlternative?: boolean;
	typeMedia?: string;
};

export default function SingleVideo({
	cardItem,
	alterTitle,
	noLowerDescription,
	manualPause,
	dataLayer,
	isVertical,
	isLiveBlog,
	isAlternative,
	typeMedia,
}: Props) {
	const path = usePathname();
	const [isPlaying, setPlaying] = useState<boolean>(false);

	const handleClick = () => {
		if (dataLayer?.isLiveBlog) {
			handleSelectContentECommerce(
				[
					{
						index: dataLayer.index,
						quantity: dataLayer.index,
						item_brand: "VOD",
						item_category: undefined,
						item_category2: dataLayer?.currentPath,
						item_category3: "Video",
						item_id: dataLayer?.buttonId,
						item_name: dataLayer?.content_name,
						item_variant: "Video",
						item_list_id: "live_blog",
						item_list_name: "liveBlog",
					},
				],
				"live_blog",
				"liveBlog",
				"select_item"
			);
		} else {
			handleSelectContent(dataLayer, 0, path || "");
		}
	};
	return (
		<>
			<figure
				className={`${
					style["singleVideo__VideoWrapper"] +
					" " +
					(isVertical ? style.verticalFormat : "")
				}`}
			>
				{!isPlaying ? (
					<>
						<span
							className={`${style["singleVideo__OverlayPlay"]}`}
							onClick={() => {
								setPlaying(true);
								handleClick();
							}}
						/>
						<Image
							src={
								(cardItem?.image?.imageUrl || cardItem?.cover) ??
								"/assets/placeholder_.svg"
							}
							className={`${
								isLiveBlog && typeMedia === "multivideo"
									? `${style["singleVideo__liveblog--aspectRatio"]}`
									: ""
							}`}
							alt={cardItem?.description || alterTitle}
							width={isLiveBlog ? 1920 : 368}
							height={isLiveBlog ? 1280 : 207}
							loading="lazy"
							placeholder="blur"
							blurDataURL={`${
								process.env.NEXT_PUBLIC_BLURIMAGE ||
								"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
							}`}
							quality={isLiveBlog ? 100 : 50}
							sizes={
								isLiveBlog
									? "(max-width:834px) 100vw, (max-width:1024px) 50vw, (min-width:1440) 100vw, 100vw"
									: ""
							}
						/>
						{!isAlternative && (
							<button
								className={`${style["singleVideo__playButton"]} ${
									typeMedia && typeMedia === "multivideo"
										? style["singleVideo__playButton--size"]
										: ""
								}`}
								aria-label={"Reproducir video"}
							>
								<PlayArrowSharpIcon />
							</button>
						)}
						{isAlternative && (
							<button
								className={`${style["singleVideo__playButtonDuration"]}`}
								aria-label={"Reproducir video"}
							>
								<p>{cardItem?.duration ?? "00:00"}</p>
								<PlayArrowSharpIcon />
							</button>
						)}
					</>
				) : (
					<>
						<div className="lds-dual-ring" />
						<PlayerVodV2
							cmsid
							idVideo={cardItem?.cmsid}
							autoplay={true}
							manualPause={manualPause}
							mute={false}
							currentPlaceholder={cardItem?.image?.imageUrl || cardItem?.cover}
						/>
					</>
				)}
			</figure>
			{cardItem?.description?.length > 0 && !noLowerDescription && (
				<div
					className={`${
						isLiveBlog
							? style["singleVideo__liveblog__CaptionWrapper"]
							: style["singleVideo__CaptionWrapper"]
					}`}
				>
					<p
						className={`${
							isLiveBlog
								? style["singleVideo__liveblog__Caption"]
								: style["singleVideo__Caption"]
						}`}
					>
						{sanitizeText({
							text: cardItem?.description || "",
							maxCharacters: 256,
						})}
					</p>
				</div>
			)}
		</>
	);
}
